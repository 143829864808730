<template>
    <section id="aboutFooter" class="bg-about-footer">
        <v-img
            dark
            :src="require('@/assets/Backgrounds/bg-aboutus-footer.png')"
            class="white--text "
        >
            <v-container class="fill-height px-4 py-12">
            <v-responsive
                class="d-flex align-start text-position"
                data-aos="fade-right"
                data-aos-duration="1500"
                data-aos-delay="100"
            >
                <v-container>
                    <p class="title--about--footer1 text-center text-lg-h6 text-body-1 font-weight-bold">
                        {{ text1 }}
                    </p>
                </v-container>
            </v-responsive>
        </v-container>
        </v-img>
        <v-container class="px-4 py-12">
            <v-responsive
                class="d-flex align-start text-position"
                data-aos="fade-right"
                data-aos-duration="1500"
                data-aos-delay="100"
            >
                <v-container>
                    <p class="title--about--footer2 text-center font-weight-light text-lg-h6 text-body-1">
                        {{ text2 }}
                    </p>
                </v-container>
            </v-responsive>
        </v-container>
    </section>
</template>

<script>
export default {
    name: 'SectionAboutFooter',
    data() {
        return {
            text1: "Hemos contado entre nuestros clientes las principales firmas de ingeniería del país tales como Castro Tcherassi SA, Concay SA, Compañía de Trabajos Urbanos CTU. Conciviles, Conconcreto SA, Concrescol SA, Coninsa Ramon H, Doble A Ingeniería, Dromos Pavimentos, Esgamo Ltda, Graico, ICEIN, Murcia Murcia SA , Obresca SA, Odinsa, Pavimentos Colombia, SAINC SA, Renting de Antioquía SAS, Asfaltart SAS, JMV Ingenieros SAS, Grupo Colpao SAS, SACYR Construcciones Colombia SAS, Asfaltos Medellín SAS, Construcciones El Condor SA, entre otras.",
            text2: "Nosotros esperamos dar cumplimiento a todas las expectativas que nuestros clientes nos imponen, para ello contamos con un personal especializado y equipos que cumplen con los mantenimientos y servicios rutinarios, con los que pretendemos ofrecer la mayor confiabilidad a nuestros clientes.",
        }
    },
}
</script>

<style lang="scss">
#aboutFooter {
    .title--about--footer2 { margin: 3vw 0; }
}
</style>